<script>
  let isMenuOpen = false;
  const toggleMenu = () => (isMenuOpen = !isMenuOpen);

  const stats = [
    {
      value: '99.99%',
      label: 'Uptime',
      icon: 'fa-solid fa-server',
    },
    {
      value: '<100ms',
      label: 'Latency',
      icon: 'fa-solid fa-bolt',
    },
    {
      value: '50M+',
      label: 'Messages',
      icon: 'fa-solid fa-message',
    },
    {
      value: '24/7',
      label: 'Support',
      icon: 'fa-solid fa-headset',
    },
  ];
</script>

<nav class="fixed top-0 left-0 right-0 bg-white z-50">
  <div class="max-w-6xl mx-auto px-6">
    <div class="flex items-center justify-between h-20">
      <!-- Logo -->
      <a
        href="/"
        style="border-radius: 32px;"
        class="bg-black px-4 py-2 rounded-full w-[120px] h-[40px] flex items-center justify-center"
      >
        <img
          style="height: 30px;"
          src="/logo.png"
          alt="Qflit"
        />
      </a>

      <!-- Main Nav -->
      <nav class="desktop-only flex items-center gap-12">
        <a href="#features" class="nav-link">Features</a>
        <a href="#integrations" class="nav-link"
          >Integrations</a
        >
        <a href="#pricing" class="nav-link">Pricing</a>
        <a href="#contact" class="nav-link">Contact</a>
      </nav>

      <!-- Actions -->
      <div
        class="desktop-only text-white flex items-center gap-6"
      >
        <a href="/docs" class="nav-link">Docs</a>

        <a
          href="https://app.qflit.com"
          class="font-outfit text-[15px] px-5 py-2.5 bg-black text-white rounded-full"
        >
          Get Started
        </a>
      </div>

      <!-- Mobile Menu Toggle -->
      <button
        style="border:none;"
        class="mobile-only bg-white text-black rounded-3xl px-8 py-3 font-medium shadow-[0_4px_12px_rgba(0,0,0,0.16)] hover:shadow-[0_6px_16px_rgba(0,0,0,0.22)] transition-shadow border-none flex items-center gap-1"
        on:click="{toggleMenu}"
      >
        {#if isMenuOpen}
          <i class="fas fa-times text-xl"></i>
        {:else}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            color="black"
            fill="none"
          >
            <path
              d="M4 9L20 9"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M4 15L14 15"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        {/if}
      </button>
    </div>

    <!-- Mobile Menu -->
    {#if isMenuOpen}
      <div
        style="min-height: 100vh;"
        class="mobile-menu text-black"
      >
        <a
          on:click="{toggleMenu}"
          href="#features"
          class="nav-link">Features</a
        >
        <a
          on:click="{toggleMenu}"
          href="#integrations"
          class="nav-link">Integrations</a
        >

        <a
          on:click="{toggleMenu}"
          href="#pricing"
          class="nav-link">Pricing</a
        >
        <a
          on:click="{toggleMenu}"
          href="#contact"
          class="nav-link">Contact</a
        >
        <a
          on:click="{toggleMenu}"
          href="https://docs.qflit.com"
          class="nav-link">Docs</a
        >
        <div class="text-white">
          <a
            on:click="{toggleMenu}"
            href="https://app.qflit.com"
            class="font-outfit text-[15px] px-5 py-2.5 bg-black text-white rounded-full text-center"
          >
            Get Started
          </a>
        </div>
      </div>
    {/if}
  </div>
</nav>

<section
  class="relative mt-32 pt:4 md:pt-16 pb-20 px-6 hero"
>
  <div class="max-w-6xl mx-auto text-center">
    <!-- Main Heading -->
    <h1
      class="font-outfit font-extrabold text-3xl md:text-6xl lg:text-7xl max-w-4xl mx-auto leading-tight"
    >
      Build AI Assistants Without Infrastructure Headaches
    </h1>

    <!-- Subheading -->
    <p
      class="font-outfit text-lg md:text-xl text-gray-600 mt-6 max-w-2xl mx-auto"
    >
      Transform OpenAI's Assistants API into
      production-ready features in minutes, not weeks.
    </p>

    <!-- CTA Buttons -->
    <div
      class="flex items-center text-white justify-center gap-4 mt-12"
    >
      <a
        href="https://app.qflit.com"
        class="font-outfit text-[15px] px-8 py-3.5 bg-black text-white rounded-full hover:bg-gray-900 transition-colors w-full sm:w-auto"
      >
        Get Started
      </a>

      <a
        href="https://docs.qflit.com"
        class="font-outfit text-[15px] px-8 py-3.5 border border-gray-200 rounded-full hover:border-gray-400 transition-colors w-full sm:w-auto"
      >
        <span class="text-black">Read Docs</span>
      </a>
    </div>

    <!-- Stats Row -->
    <div
      class="grid grid-cols-4 md:grid-cols-4 gap-3 md:gap-8 mt-16 max-w-4xl mx-auto"
    >
      {#each stats as stat}
        <div class="flex flex-col items-center">
          <div
            class="bg-black/5 rounded-full w-12 h-12 flex items-center justify-center mb-3"
          >
            <i class="{stat.icon} md:text-xl"></i>
          </div>
          <div class="font-outfit font-bold md:text-2xl">
            {stat.value}
          </div>
          <div
            class="font-outfit text-gray-600 text-sm mt-1"
          >
            {stat.label}
          </div>
        </div>
      {/each}
    </div>
  </div>
</section>

<section
  class="py-12 md:py-24 px-6 relative overflow-hidden"
>
  <!-- Gradient Backgrounds -->
  <div
    class="absolute top-0 left-[15%] w-[1000px] h-[1000px] opacity-[0.15] blur-[100px] pointer-events-none bg-gradient-to-r from-blue-500 to-purple-500 rounded-full"
  ></div>
  <div
    class="absolute bottom-0 right-[15%] w-[1000px] h-[1000px] opacity-[0.15] blur-[100px] pointer-events-none bg-gradient-to-r from-green-500 to-blue-500 rounded-full"
  ></div>

  <div id="features" class="max-w-6xl mx-auto relative">
    <!-- Section Header -->
    <div class="text-center mb-20">
      <h2
        class="font-outfit font-bold text-3xl md:text-5xl mb-6"
      >
        Complex AI Infrastructure, Made Simple
      </h2>
      <p
        class="font-outfit text-gray-600 text-lg max-w-2xl mx-auto"
      >
        Replace weeks of infrastructure setup with our
        drop-in solution. Handle messages, events, and
        conversations with just a few lines of code.
      </p>
    </div>

    <!-- Features Grid -->
    <div class="grid md:grid-cols-3 gap-8">
      <!-- Message Handling -->
      <div class="glass-card">
        <div class="h-full flex flex-col">
          <div
            class="icon-wrapper bg-gradient-to-br from-blue-500 to-blue-600"
          >
            <i class="fa-solid fa-message text-white"></i>
          </div>
          <h3
            class="font-outfit font-semibold text-xl mt-6"
          >
            Drop-in Message Handler
          </h3>
          <p
            class="font-outfit text-gray-600 mt-3 text-[15px] leading-relaxed flex-grow"
          >
            Replace complex API integrations with our simple
            endpoints. Send messages via REST or WebSocket -
            we handle the OpenAI communication for you.
          </p>
        </div>
      </div>

      <!-- Event Streaming -->
      <div class="glass-card">
        <div class="h-full flex flex-col">
          <div
            class="icon-wrapper bg-gradient-to-br from-purple-500 to-purple-600"
          >
            <i class="fa-solid fa-bolt text-white"></i>
          </div>
          <h3
            class="font-outfit font-semibold text-xl mt-6"
          >
            Zero-Config Event Streaming
          </h3>
          <p
            class="font-outfit text-gray-600 mt-3 text-[15px] leading-relaxed flex-grow"
          >
            Forget about complex event stream handling. We
            manage the OpenAI infrastructure - you just
            receive real-time updates through simple
            WebSocket events or Webhooks.
          </p>
        </div>
      </div>

      <!-- Thread Management -->
      <div class="glass-card">
        <div class="h-full flex flex-col">
          <div
            class="icon-wrapper bg-gradient-to-br from-green-500 to-green-600"
          >
            <i class="fa-solid fa-layer-group text-white"
            ></i>
          </div>
          <h3
            class="font-outfit font-semibold text-xl mt-6"
          >
            Automated Thread Manager
          </h3>
          <p
            class="font-outfit text-gray-600 mt-3 text-[15px] leading-relaxed flex-grow"
          >
            Stop building conversation management systems.
            Our thread manager automatically routes and
            tracks all your AI conversations with built-in
            state handling.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  id="integrations"
  class="py-24 px-6 relative overflow-hidden bg-gray-50"
>
  <!-- Subtle gradient background -->
  <div
    class="absolute top-0 left-[25%] w-[1000px] h-[1000px] opacity-[0.1] blur-[100px] pointer-events-none bg-gradient-to-r from-purple-500 to-blue-500 rounded-full"
  ></div>

  <div class="max-w-6xl mx-auto relative">
    <!-- Section Header -->
    <div class="text-center mb-20">
      <h2
        class="font-outfit font-bold text-3xl md:text-5xl mb-6"
      >
        Connect Your Systems Without The Complexity
      </h2>
      <p
        class="font-outfit text-gray-600 text-lg max-w-2xl mx-auto"
      >
        Seamlessly integrate AI assistants with your
        existing infrastructure. No boilerplate code needed.
      </p>
    </div>

    <!-- Main Feature Card -->
    <div class="glass-card mb-12">
      <div
        class="flex flex-col md:flex-row gap-8 items-center"
      >
        <div class="flex-1">
          <div
            class="icon-wrapper bg-gradient-to-br from-purple-500 to-purple-600 mb-6"
          >
            <i class="fa-solid fa-plug text-white"></i>
          </div>
          <h3
            class="font-outfit font-semibold text-2xl mb-4"
          >
            Assistant Functions & External Integrations
          </h3>
          <p
            class="font-outfit text-gray-600 text-[15px] leading-relaxed mb-6"
          >
            Create assistant functions and define their
            parameters visually in Qflit's interface. Simply
            attach your endpoints to each function for
            execution via HTTP requests or WebSocket
            connections - we'll automate everything from the
            assistant's function call to handling responses.
          </p>
          <div class="flex flex-wrap gap-3">
            <span
              class="px-3 py-1.5 bg-purple-50 text-purple-700 rounded-full text-sm font-outfit"
              >REST APIs</span
            >
            <span
              class="px-3 py-1.5 bg-blue-50 text-blue-700 rounded-full text-sm font-outfit"
              >GraphQL</span
            >
            <span
              class="px-3 py-1.5 bg-green-50 text-green-700 rounded-full text-sm font-outfit"
              >WebSocket</span
            >
            <span
              class="px-3 py-1.5 bg-orange-50 text-orange-700 rounded-full text-sm font-outfit"
              >HTTP Requests</span
            >
          </div>
        </div>
        <div class="flex-1">
          <!-- Function Flow Visualization -->
          <div
            class="bg-black/5 rounded-2xl p-6 backdrop-blur-sm"
          >
            <div
              class="flex items-center justify-between gap-4 mb-4"
            >
              <div class="flex items-center gap-2">
                <i class="fa-solid fa-robot text-gray-700"
                ></i>
                <span class="font-outfit text-sm"
                  >Assistant</span
                >
              </div>
              <i
                class="fa-solid fa-arrow-right text-gray-500"
              ></i>
              <div class="flex items-center gap-2">
                <i class="fa-solid fa-server text-gray-700"
                ></i>
                <span class="font-outfit text-sm"
                  >Your API</span
                >
              </div>
            </div>
            <div
              class="font-mono text-sm bg-black/5 p-3 rounded-lg"
            >
              function: get_user_data params: {'user_id : 123'}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Feature Points -->
    <div class="grid md:grid-cols-3 gap-8">
      <div class="glass-card">
        <h4 class="font-outfit font-semibold text-lg mb-3">
          Instant Function Execution
        </h4>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          When the assistant calls a function, Qflit
          automatically handles the execution - either
          sending parameters via HTTP request (as query
          params or POST data) or through WebSocket
          connections, then seamlessly returning the results
          back to the assistant.
        </p>
      </div>

      <div class="glass-card">
        <h4 class="font-outfit font-semibold text-lg mb-3">
          Data Transformation
        </h4>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Shape assistant function parameters using JSON
          mappings or GraphQL queries before making network
          requests. Transform API response data using JSON
          mappings or JavaScript functions to match the
          assistant's requirements.
        </p>
      </div>

      <div class="glass-card">
        <h4 class="font-outfit font-semibold text-lg mb-3">
          Flexible Authentication
        </h4>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Set up Bearer tokens, Basic Auth, custom headers,
          or define reusable auth templates for your
          endpoints. Configure once and securely attach to
          any assistant function - we'll handle the
          authentication on every request.
        </p>
      </div>
    </div>
  </div>
</section>

<section
  class="py-24 px-6 relative overflow-hidden glass-card-styled-bg"
>
  <!-- Gradient Background -->
  <div
    class="absolute top-0 right-[25%] w-[1000px] h-[1000px] opacity-[0.1] blur-[100px] pointer-events-none bg-gradient-to-r from-green-500 to-blue-500 rounded-full"
  ></div>

  <div class="max-w-6xl mx-auto relative">
    <!-- Section Header -->
    <div class="text-center mb-20">
      <h2
        class="font-outfit font-bold text-4xl md:text-5xl mb-6"
      >
        Assistant Tools with Zero-Setup CDN
      </h2>
      <p
        class="font-outfit text-gray-600 text-lg max-w-2xl mx-auto"
      >
        Simplify how you use Code Interpreter and File
        Search. Attach files seamlessly, access generated
        content easily, and get cleaner results.
      </p>
    </div>

    <!-- Main Feature Area -->
    <div class="mb-12 glass-card">
      <div
        class="flex flex-col md:flex-row gap-12 items-center"
      >
        <div class="flex-1">
          <div
            class="icon-wrapper bg-gradient-to-br from-green-500 to-green-600 mb-6"
          >
            <i class="fa-solid fa-file-code text-white"></i>
          </div>
          <h3
            class="font-outfit font-semibold text-2xl mb-4"
          >
            Code Interpreter & File Search
          </h3>
          <p
            class="font-outfit text-gray-600 text-[15px] leading-relaxed mb-6"
          >
            Attach files directly with incoming messages
            (something not possible with direct OpenAI
            integration) or through our UI. Generated files
            from Code Interpreter get instant hosting with
            accessible links, while File Search results come
            with automatically cleaned annotations.
          </p>
          <!-- Supported File Types -->
          <div class="grid grid-cols-2 gap-3 mt-6">
            <div
              class="flex items-center gap-2 text-gray-600"
            >
              <i class="fa-solid fa-table text-sm"></i>
              <span class="text-sm">CSV & Excel</span>
            </div>
            <div
              class="flex items-center gap-2 text-gray-600"
            >
              <i class="fa-solid fa-file-pdf text-sm"></i>
              <span class="text-sm">PDFs</span>
            </div>
            <div
              class="flex items-center gap-2 text-gray-600"
            >
              <i class="fa-solid fa-file-image text-sm"></i>
              <span class="text-sm">Images</span>
            </div>
            <div
              class="flex items-center gap-2 text-gray-600"
            >
              <i class="fa-solid fa-file-lines text-sm"></i>
              <span class="text-sm">Text Files</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Features Grid -->
    <div class="grid md:grid-cols-3 gap-8">
      <div class="glass-card">
        <h4 class="font-outfit font-semibold text-lg mb-3">
          Message Attachments
        </h4>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Attach files directly with your messages to the
          assistant - a feature not available with standard
          OpenAI integration. Files are automatically added
          to the conversation.
        </p>
      </div>

      <div class="glass-card">
        <h4 class="font-outfit font-semibold text-lg mb-3">
          Built-in CDN
        </h4>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          When Code Interpreter generates files - whether
          images, data, or documents - we automatically host
          them and provide direct access links in the
          conversation.
        </p>
      </div>

      <div class="glass-card">
        <h4 class="font-outfit font-semibold text-lg mb-3">
          Clean Responses
        </h4>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          When the assistant uses File Search, we
          automatically clean up its responses by removing
          annotation markers. Get clear, readable answers
          without formatting artifacts.
        </p>
      </div>
    </div>
  </div>
</section>

<section
  class="py-24 px-6 relative overflow-hidden bg-gray-50"
>
  <!-- Gradient Background -->
  <div
    class="absolute bottom-0 left-[25%] w-[1000px] h-[1000px] opacity-[0.1] blur-[100px] pointer-events-none bg-gradient-to-r from-blue-500 to-purple-500 rounded-full"
  ></div>

  <div class="max-w-6xl mx-auto relative">
    <!-- Section Header -->
    <div class="text-center mb-20">
      <h2
        class="font-outfit font-bold text-3xl md:text-5xl mb-6"
      >
        Enterprise-Grade Infrastructure
      </h2>
      <p
        class="font-outfit text-gray-600 text-lg max-w-2xl mx-auto"
      >
        Production-ready logging, error handling, and
        recovery systems built-in. Focus on your product,
        not infrastructure maintenance.
      </p>
    </div>

    <!-- Main Feature Grid -->
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <!-- Logging System -->
      <div class="glass-card">
        <div
          class="icon-wrapper bg-gradient-to-br from-blue-500 to-blue-600 mb-6"
        >
          <i
            class="fa-solid fa-magnifying-glass-chart text-white"
          ></i>
        </div>
        <h3 class="font-outfit font-semibold text-xl mb-4">
          Comprehensive Logging
        </h3>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed mb-6"
        >
          Debug with confidence using our detailed logging
          system. Track every API call, function execution,
          and system event in one place.
        </p>
        <div
          class="bg-black/5 rounded-xl p-4 font-mono text-sm"
        >
          <div
            class="flex items-center gap-2 text-gray-600 mb-2"
          >
            <i
              class="fa-solid fa-circle text-green-500 text-xs"
            ></i>
            API Request: /messages [200 OK]
          </div>
          <div
            class="flex items-center gap-2 text-gray-600 mb-2"
          >
            <i
              class="fa-solid fa-circle text-blue-500 text-xs"
            ></i>
            Function Executed: get_user_data
          </div>
          <div
            class="flex items-center gap-2 text-gray-600"
          >
            <i
              class="fa-solid fa-circle text-purple-500 text-xs"
            ></i>
            Assistant Response: thread.message.completed
          </div>
        </div>
      </div>

      <!-- Error Recovery -->
      <div class="glass-card">
        <div
          class="icon-wrapper bg-gradient-to-br from-purple-500 to-purple-600 mb-6"
        >
          <i class="fa-solid fa-shield-halved text-white"
          ></i>
        </div>
        <h3 class="font-outfit font-semibold text-xl mb-4">
          Intelligent Error Recovery
        </h3>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed mb-6"
        >
          Automatic handling of errors and retries. Built-in
          fallbacks ensure your AI applications stay
          reliable.
        </p>
        <div class="bg-black/5 rounded-xl p-4">
          <div
            class="flex items-center justify-between mb-3"
          >
            <span class="font-outfit text-sm text-gray-600"
              >Automatic Retries</span
            >
            <i class="fa-solid fa-check text-green-500"></i>
          </div>
          <div
            class="flex items-center justify-between mb-3"
          >
            <span class="font-outfit text-sm text-gray-600"
              >Network Timeout Handling</span
            >
            <i class="fa-solid fa-check text-green-500"></i>
          </div>
          <div class="flex items-center justify-between">
            <span class="font-outfit text-sm text-gray-600"
              >Graceful Degradation</span
            >
            <i class="fa-solid fa-check text-green-500"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Additional Features -->
    <div class="grid md:grid-cols-3 gap-8">
      <div class="glass-card">
        <h4 class="font-outfit font-semibold text-lg mb-3">
          Real-time Monitoring
        </h4>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Monitor system health, performance metrics, and
          usage patterns in real-time.
        </p>
      </div>

      <div class="glass-card">
        <h4 class="font-outfit font-semibold text-lg mb-3">
          Smart Rate Limiting
        </h4>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Automatic request queuing and rate limit handling
          to prevent API throttling.
        </p>
      </div>

      <div class="glass-card">
        <h4 class="font-outfit font-semibold text-lg mb-3">
          Enterprise-level Security
        </h4>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Bank-grade encryption, SOC 2 compliance, and
          enterprise-level infrastructure security.
        </p>
      </div>
    </div>
  </div>
</section>
<section
  id="pricing"
  class="py-24 px-6 relative overflow-hidden"
>
  <!-- Animated Gradient Background -->
  <div
    class="absolute inset-0 bg-gradient-to-br from-green-50 to-blue-50"
  ></div>
  <div
    class="absolute top-0 right-[15%] w-[1000px] h-[1000px] opacity-[0.15] blur-[100px] animate-pulse pointer-events-none bg-gradient-to-r from-purple-500 to-blue-500 rounded-full"
  ></div>

  <div class="max-w-6xl mx-auto relative">
    <div class="text-center mb-20">
      <h2
        class="font-outfit font-bold text-3xl md:text-5xl mb-6"
      >
        Simple, Transparent Pricing
      </h2>
      <p
        class="font-outfit text-gray-600 text-lg max-w-2xl mx-auto"
      >
        Start with 50 free API calls! Purchase additional
        API calls whenever you need them - no monthly fees
        or subscriptions. Every purchase includes access to
        all core features.
      </p>
    </div>

    <div class="grid lg:grid-cols-3 gap-8 relative">
      <!-- Starter -->
      <div class="glass-card p-8 relative group">
        <div class="p-6">
          <h3 class="font-outfit font-semibold text-xl">
            50,000 API Credits
          </h3>
          <div class="mt-4 mb-8">
            <div class="flex items-baseline">
              <span class="font-outfit font-bold text-4xl"
                >$99.00</span
              >
            </div>
            <div
              class="font-outfit text-green-600 text-sm mt-2"
            >
              One-time purchase
            </div>
          </div>

          <div class="space-y-4">
            <div class="font-outfit text-[15px]">
              Perfect for:
            </div>
            <div class="flex items-center gap-3">
              <i class="fa-solid fa-rocket text-blue-500"
              ></i>
              <span class="font-outfit text-[15px]"
                >Small teams & startups</span
              >
            </div>
            <div class="flex items-center gap-3">
              <i
                class="fa-solid fa-wand-magic-sparkles text-purple-500"
              ></i>
              <span class="font-outfit text-[15px]"
                >MVP development</span
              >
            </div>
            <div class="flex items-center gap-3">
              <i class="fa-solid fa-code text-green-500"
              ></i>
              <span class="font-outfit text-[15px]"
                >Small projects</span
              >
            </div>
          </div>
        </div>

        <div class="text-white relative z-20">
          <a
            href="https://app.qflit.com"
            class="block text-center font-outfit text-[15px] px-6 py-3 bg-black text-white rounded-full hover:bg-gray-900 transition-all mt-8"
          >
            Start For Free
          </a>
        </div>
      </div>

      <!-- Growth -->
      <div
        class="glass-card p-8 relative group lg:-translate-y-4"
      >
        <div
          class="absolute inset-0 bg-gradient-to-br from-purple-500/[0.03] to-blue-500/[0.03] rounded-[24px]"
        ></div>
        <div class="p-6">
          <h3 class="font-outfit font-semibold text-xl">
            350,000 API Credits
          </h3>
          <div class="mt-4 mb-8">
            <div class="flex items-baseline">
              <span class="font-outfit font-bold text-4xl"
                >$249.00</span
              >
            </div>
            <div
              class="font-outfit text-green-600 text-sm mt-2"
            >
              One-time purchase
            </div>
          </div>

          <div class="space-y-4">
            <div class="font-outfit text-[15px]">
              Best suited for:
            </div>
            <div class="flex items-center gap-3">
              <i class="fa-solid fa-bolt text-yellow-500"
              ></i>
              <span class="font-outfit text-[15px]"
                >Production applications</span
              >
            </div>
            <div class="flex items-center gap-3">
              <i
                class="fa-solid fa-chart-line text-blue-500"
              ></i>
              <span class="font-outfit text-[15px]"
                >High-volume processing</span
              >
            </div>
            <div class="flex items-center gap-3">
              <i class="fa-solid fa-users text-purple-500"
              ></i>
              <span class="font-outfit text-[15px]"
                >Growing user bases</span
              >
            </div>
          </div>
        </div>

        <div class="text-white relative z-20">
          <a
            href="https://app.qflit.com"
            class="block text-center font-outfit text-[15px] px-6 py-3 bg-black text-white rounded-full hover:bg-gray-900 transition-all mt-8 group-hover:scale-[1.02] transition-transform"
          >
            Start For Free
          </a>
        </div>
        <div
          style="top:10px; z-index:10; right:10px;"
          class="absolute -translate-x-1/2 bg-black text-white px-4 py-1.5 rounded-full font-outfit text-sm whitespace-nowrap"
        >
          Most Popular
        </div>
      </div>

      <!-- Enterprise -->
      <div class="glass-card p-8 relative group">
        <div class="p-6">
          <h3 class="font-outfit font-semibold text-xl">
            Enterprise
          </h3>
          <div class="mt-4 mb-8">
            <div class="font-outfit font-bold text-2xl">
              Custom Pricing
            </div>
            <div
              class="font-outfit text-green-600 text-sm mt-2"
            >
              Tailored to your needs
            </div>
          </div>

          <div class="space-y-4">
            <div class="font-outfit text-[15px]">
              Custom features include:
            </div>
            <div class="flex items-center gap-3">
              <i
                class="fa-solid fa-shield-halved text-blue-500"
              ></i>
              <span class="font-outfit text-[15px]"
                >Advanced security</span
              >
            </div>
            <div class="flex items-center gap-3">
              <i
                class="fa-solid fa-handshake text-purple-500"
              ></i>
              <span class="font-outfit text-[15px]"
                >Dedicated support</span
              >
            </div>
            <div class="flex items-center gap-3">
              <i
                class="fa-solid fa-file-contract text-green-500"
              ></i>
              <span class="font-outfit text-[15px]"
                >Custom SLAs</span
              >
            </div>
          </div>
        </div>

        <div class="text-black relative z-20">
          <a
            href="mailto:hello@qflit.com"
            class="block text-center font-outfit text-[15px] px-6 py-3 border border-black rounded-full hover:bg-black hover:text-white transition-all mt-8"
          >
            Contact Sales
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  class="py-16 md:py-32 px-6 relative overflow-hidden"
>
  <!-- Dynamic Gradient Orbs -->
  <div
    class="absolute top-0 left-[20%] w-[800px] h-[800px] opacity-[0.15] blur-[100px] pointer-events-none bg-gradient-to-r from-purple-500 to-blue-500 rounded-full animate-pulse"
  ></div>
  <div
    class="absolute bottom-0 right-[20%] w-[600px] h-[600px] opacity-[0.15] blur-[100px] pointer-events-none bg-gradient-to-r from-green-500 to-blue-500 rounded-full animate-pulse"
    style="animation-delay: 1s;"
  ></div>

  <!-- Main Content -->
  <div class="max-w-6xl mx-auto relative">
    <div class="relative p-4 md:p-16">
      <!-- Main Title with Gradient Text -->
      <h2
        class="font-outfit font-extrabold text-3xl md:text-5xl lg:text-6xl text-center mb-6 bg-gradient-to-r from-black to-gray-600 bg-clip-text text-transparent"
      >
        Ready to Transform Your AI Dev?
      </h2>

      <!-- Engaging Subtitle -->
      <p
        class="font-outfit text-gray-600 text-lg md:text-xl text-center mb-12"
      >
        Join developers who are building the future of AI
        applications without infrastructure headaches.
      </p>

      <!-- Stats Showcase -->
      <div
        class="flex justify-center gap-12 mb-12 flex-wrap"
      >
        <div class="flex flex-col items-center">
          <div
            class="font-outfit font-bold text-3xl mb-1 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
          >
            50M+
          </div>
          <div class="font-outfit text-sm text-gray-600">
            Messages Processed
          </div>
        </div>
        <div class="flex flex-col items-center">
          <div
            class="font-outfit font-bold text-3xl mb-1 bg-gradient-to-r from-blue-600 to-green-600 bg-clip-text text-transparent"
          >
            1000+
          </div>
          <div class="font-outfit text-sm text-gray-600">
            Active Developers
          </div>
        </div>
        <div class="flex flex-col items-center">
          <div
            class="font-outfit font-bold text-3xl mb-1 bg-gradient-to-r from-green-600 to-teal-600 bg-clip-text text-transparent"
          >
            99.99%
          </div>
          <div class="font-outfit text-sm text-gray-600">
            Uptime
          </div>
        </div>
      </div>

      <!-- CTA Buttons -->
      <div
        class="flex flex-col sm:flex-row items-center justify-center gap-4"
      >
        <a
          href="https://app.qflit.com"
          class="group relative font-outfit text-[15px] px-8 py-4 bg-black text-white rounded-full hover:bg-gray-900 transition-all overflow-hidden w-full sm:w-auto text-center"
        >
          <span class="relative z-10 text-white"
            >Start Building Free</span
          >
          <div
            class="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 opacity-0 group-hover:opacity-100 transition-opacity"
          ></div>
        </a>
        <a
          href="https://docs.qflit.com"
          class="font-outfit text-[15px] px-8 py-4 border border-black rounded-full hover:bg-black hover:text-white transition-all w-full sm:w-auto text-center"
        >
          View Documentation
        </a>
      </div>

      <!-- Trust Indicators -->
      <div
        class="flex justify-center items-center gap-4 mt-12"
      >
        <i class="fa-solid fa-shield-check text-gray-400"
        ></i>
        <span class="font-outfit text-sm text-gray-500"
          >Enterprise-Ready</span
        >
        <span class="text-gray-300">|</span>
        <i class="fa-solid fa-lock text-gray-400"></i>
        <span class="font-outfit text-sm text-gray-500"
          >SOC 2 Compliant</span
        >
        <span class="text-gray-300">|</span>
        <i class="fa-solid fa-clock text-gray-400"></i>
        <span class="font-outfit text-sm text-gray-500"
          >24/7 Support</span
        >
      </div>
    </div>
  </div>
</section>

<section
  class="py-24 px-6 relative overflow-hidden bg-gray-50"
>
  <!-- Gradient Backgrounds -->
  <div
    class="absolute top-0 right-[15%] w-[1000px] h-[1000px] opacity-[0.15] blur-[100px] pointer-events-none bg-gradient-to-r from-blue-500 to-green-500 rounded-full"
  ></div>

  <div class="max-w-6xl mx-auto relative">
    <div class="text-center mb-20">
      <h2
        class="font-outfit font-bold text-4xl md:text-5xl mb-6"
      >
        Build Any AI Experience
      </h2>
      <p
        class="font-outfit text-gray-600 text-lg max-w-2xl mx-auto"
      >
        Our flexible API enables you to integrate AI
        assistants into any platform or service
      </p>
    </div>

    <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
      <!-- E-commerce -->
      <div class="glass-card group">
        <div
          class="icon-wrapper bg-gradient-to-br from-green-500 to-green-600 mb-6"
        >
          <i class="fa-solid fa-shopping-cart text-white"
          ></i>
        </div>
        <h3 class="font-outfit font-semibold text-xl mb-3">
          E-commerce Assistants
        </h3>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Connect our API to your WooCommerce or any
          e-commerce platform to create AI shopping
          assistants that help customers find products.
        </p>
        <div class="mt-4 pt-4 border-t border-gray-100">
          <div class="font-outfit text-sm text-gray-500">
            Build for platforms like:
          </div>
          <div class="flex items-center gap-2 mt-2">
            <i class="fa-brands fa-wordpress text-gray-400"
            ></i>
            <i class="fa-brands fa-shopify text-gray-400"
            ></i>
            <i
              class="fa-solid fa-shopping-bag text-gray-400"
            ></i>
          </div>
        </div>
      </div>

      <!-- Messaging -->
      <div class="glass-card group">
        <div
          class="icon-wrapper bg-gradient-to-br from-green-600 to-green-700 mb-6"
        >
          <i class="fa-brands fa-whatsapp text-white"></i>
        </div>
        <h3 class="font-outfit font-semibold text-xl mb-3">
          Messaging Integration
        </h3>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Build chatbots for WhatsApp, Instagram, or any
          messaging platform using our API for natural
          conversational experiences.
        </p>
        <div class="mt-4 pt-4 border-t border-gray-100">
          <div class="font-outfit text-sm text-gray-500">
            Build for platforms like:
          </div>
          <div class="flex items-center gap-2 mt-2">
            <i class="fa-brands fa-whatsapp text-gray-400"
            ></i>
            <i class="fa-brands fa-instagram text-gray-400"
            ></i>
            <i
              class="fa-brands fa-facebook-messenger text-gray-400"
            ></i>
          </div>
        </div>
      </div>

      <!-- Support -->
      <div class="glass-card group">
        <div
          class="icon-wrapper bg-gradient-to-br from-blue-500 to-blue-600 mb-6"
        >
          <i class="fa-solid fa-headset text-white"></i>
        </div>
        <h3 class="font-outfit font-semibold text-xl mb-3">
          Support Automation
        </h3>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Integrate with your existing help desk system to
          automate ticket responses and provide 24/7
          customer support.
        </p>
        <div class="mt-4 pt-4 border-t border-gray-100">
          <div class="font-outfit text-sm text-gray-500">
            Build for platforms like:
          </div>
          <div class="flex items-center gap-2 mt-2">
            <i class="fa-solid fa-ticket text-gray-400"></i>
            <i class="fa-solid fa-life-ring text-gray-400"
            ></i>
            <i class="fa-solid fa-headset text-gray-400"
            ></i>
          </div>
        </div>
      </div>

      <!-- Website -->
      <div class="glass-card group">
        <div
          class="icon-wrapper bg-gradient-to-br from-gray-500 to-blue-500 mb-6"
        >
          <i class="fa-solid fa-comments text-white"></i>
        </div>
        <h3 class="font-outfit font-semibold text-xl mb-3">
          Custom Chat Interfaces
        </h3>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Create customized chat experiences for your
          website using our API. Handle FAQs and support
          queries your way.
        </p>
        <div class="mt-4 pt-4 border-t border-gray-100">
          <div class="font-outfit text-sm text-gray-500">
            Build with:
          </div>
          <div class="flex items-center gap-2 mt-2">
            <i class="fa-brands fa-react text-gray-400"></i>
            <i class="fa-brands fa-vuejs text-gray-400"></i>
            <i class="fa-brands fa-angular text-gray-400"
            ></i>
          </div>
        </div>
      </div>

      <!-- Knowledge -->
      <div class="glass-card group">
        <div
          class="icon-wrapper bg-gradient-to-br from-purple-500 to-pink-500 mb-6"
        >
          <i class="fa-solid fa-book-open text-white"></i>
        </div>
        <h3 class="font-outfit font-semibold text-xl mb-3">
          Knowledge Assistants
        </h3>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Build AI assistants that understand your
          documentation and provide accurate answers from
          your knowledge base.
        </p>
        <div class="mt-4 pt-4 border-t border-gray-100">
          <div class="font-outfit text-sm text-gray-500">
            Process formats like:
          </div>
          <div class="flex items-center gap-2 mt-2">
            <i class="fa-solid fa-file-pdf text-gray-400"
            ></i>
            <i class="fa-solid fa-file-word text-gray-400"
            ></i>
            <i class="fa-solid fa-file-lines text-gray-400"
            ></i>
          </div>
        </div>
      </div>

      <!-- Custom -->
      <div class="glass-card group">
        <div
          class="icon-wrapper bg-gradient-to-br from-blue-500 to-green-500 mb-6"
        >
          <i
            class="fa-solid fa-wand-magic-sparkles text-white"
          ></i>
        </div>
        <h3 class="font-outfit font-semibold text-xl mb-3">
          Custom Solutions
        </h3>
        <p
          class="font-outfit text-gray-600 text-[15px] leading-relaxed"
        >
          Use our flexible API to build unique AI
          experiences for your specific use case or industry
          needs.
        </p>
        <div class="mt-4 pt-4 border-t border-gray-100">
          <div class="font-outfit text-sm text-gray-500">
            Perfect for:
          </div>
          <div class="flex items-center gap-2 mt-2">
            <i class="fa-solid fa-building text-gray-400"
            ></i>
            <i class="fa-solid fa-industry text-gray-400"
            ></i>
            <i class="fa-solid fa-chart-line text-gray-400"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="py-32 px-6 relative overflow-hidden">
  <div
    class="absolute inset-0 bg-gradient-to-br from-blue-50/40 to-purple-50/40"
  ></div>

  <div
    class="absolute -top-40 right-1/4 w-[500px] h-[500px] opacity-[0.07] blur-[100px] bg-gradient-to-br from-blue-600 to-purple-600 rounded-full animate-pulse"
  ></div>
  <div
    class="absolute bottom-20 left-1/4 w-[300px] h-[300px] opacity-[0.05] blur-[80px] bg-gradient-to-tr from-green-400 to-blue-500 rounded-full"
  ></div>

  <div id="contact" class="max-w-3xl mx-auto relative">
    <div class="px-8 md:px-12 py-8 md:py-12 text-center">
      <div class="mb-8">
        <svg
          class="w-12 h-12 mx-auto mb-6 text-blue-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="{1.5}"
            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
          ></path>
        </svg>
        <h2
          class="font-outfit font-bold text-4xl md:text-5xl mb-4 bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent"
        >
          Need Support?
        </h2>
      </div>

      <p class="font-outfit text-gray-600 mb-12 text-lg">
        Our team is here to help you build amazing things
      </p>

      <div class="inline-block z-20 relative">
        <a
          href="mailto:hello@qflit.com"
          class="font-outfit text-2xl text-gray-600 hover:text-gray-900 transition-colors duration-300 group flex items-center gap-3"
        >
          hello@qflit.com
          <span
            class="group-hover:translate-x-1 transition-transform duration-300"
          >
            <svg
              class="w-5 h-5 opacity-70"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              ></path>
            </svg>
          </span>
        </a>
      </div>
    </div>
  </div>
</section>

<footer
  class="bg-black text-white py-16 px-6 relative overflow-hidden"
>
  <!-- Subtle Gradient Overlay -->
  <div
    class="absolute inset-0 bg-gradient-to-br from-black to-gray-900"
  ></div>

  <div class="max-w-6xl mx-auto relative">
    <!-- Main Footer Content -->
    <div class="grid md:grid-cols-4 gap-12 mb-16">
      <!-- Brand Column -->
      <div class="space-y-6">
        <!-- Logo -->
        <div
          class="rounded-full gap-2 w-[120px] h-[40px] flex"
        >
          <img
            src="/logo.png"
            alt="Qflit"
            style="height: 30px;"
          />
          <p class="text-white">Qflit AI</p>
        </div>
        <p
          style="max-width: 300px;"
          class="font-outfit text-gray-400 text-sm"
        >
          Transform OpenAI's Assistants API into
          production-ready features in minutes.
        </p>
        <div class="flex items-center space-x-4">
          <a
            href="https://twitter.com/qflit"
            class="text-gray-400 hover:text-white transition-colors"
          >
            <i class="fa-brands fa-twitter text-xl"></i>
          </a>
          <a
            href="https://github.com/qflit"
            class="text-gray-400 hover:text-white transition-colors"
          >
            <i class="fa-brands fa-github text-xl"></i>
          </a>
          <a
            href="https://discord.gg/qflit"
            class="text-gray-400 hover:text-white transition-colors"
          >
            <i class="fa-brands fa-facebook text-xl"></i>
          </a>
        </div>
      </div>

      <!-- Product -->
      <div>
        <h3
          class="font-outfit font-semibold text-sm mb-4 text-gray-300"
        >
          Product
        </h3>
        <ul class="space-y-3">
          <li>
            <a
              href="#features"
              class="font-outfit text-gray-400 hover:text-white text-sm transition-colors"
              >Features</a
            >
          </li>
          <li>
            <a
              href="#pricing"
              class="font-outfit text-gray-400 hover:text-white text-sm transition-colors"
              >Pricing</a
            >
          </li>
          <li>
            <a
              href="https://docs.qflit.com"
              class="font-outfit text-gray-400 hover:text-white text-sm transition-colors"
              >Documentation</a
            >
          </li>
        </ul>
      </div>

      <!-- Company -->
      <div>
        <h3
          class="font-outfit font-semibold text-sm mb-4 text-gray-300"
        >
          API
        </h3>
        <ul class="space-y-3">
          <li>
            <a
              href="https://docs.qflit.com/api-cheatsheett"
              class="font-outfit text-gray-400 hover:text-white text-sm transition-colors"
              >Cheatsheet</a
            >
          </li>
          <li>
            <a
              href="https://docs.qflit.com/websocket-api/connecting"
              class="font-outfit text-gray-400 hover:text-white text-sm transition-colors"
              >Websockets</a
            >
          </li>
          <li>
            <a
              href="https://docs.qflit.com/event-reference"
              class="font-outfit text-gray-400 hover:text-white text-sm transition-colors"
              >Event Ref</a
            >
          </li>
        </ul>
      </div>

      <!-- Legal -->
      <div>
        <h3
          class="font-outfit font-semibold text-sm mb-4 text-gray-300"
        >
          Legal
        </h3>
        <ul class="space-y-3">
          <li>
            <a
              href="/privacy"
              class="font-outfit text-gray-400 hover:text-white text-sm transition-colors"
              >Privacy Policy</a
            >
          </li>
          <li>
            <a
              href="/terms"
              class="font-outfit text-gray-400 hover:text-white text-sm transition-colors"
              >Terms of Service</a
            >
          </li>
        </ul>
      </div>
    </div>

    <!-- Bottom Bar -->
    <div
      class="pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0"
    >
      <div class="font-outfit text-sm text-gray-400">
        © {new Date().getFullYear()} Qflit. All rights reserved.
      </div>
      <div class="flex items-center space-x-6">
        <a
          href="#"
          class="font-outfit text-sm text-gray-400 hover:text-white transition-colors"
          >English</a
        >
        <a
          href="#"
          class="font-outfit text-sm text-gray-400 hover:text-white transition-colors"
          >Support</a
        >
      </div>
    </div>
  </div>
</footer>

<style>
  .nav-link {
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    color: black;
  }

  .mobile-only {
    display: none;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem 0;
  }

  @media (max-width: 1024px) {
    .desktop-only {
      display: none;
    }

    .mobile-only {
      display: block;
    }
  }
  .hero {
    background: radial-gradient(
      circle at center,
      rgba(0, 255, 127, 0.05) 0%,
      transparent 70%
    );
  }

  @media (max-width: 768px) {
    .stats-row {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .glass-card {
    padding: 2rem;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    height: 100%;
  }

  .glass-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(199, 231, 255, 0.35) 0%,
      transparent 70%
    );
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .glass-card-styled-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      circle at 50% 50%,
      rgba(199, 231, 255, 0.35) 0%,
      transparent 70%
    );
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .glass-card:hover {
    transform: translateY(-4px);
    border-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.075);
  }

  .glass-card:hover::before {
    opacity: 1;
  }

  .icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .icon-wrapper i {
    font-size: 20px;
  }

  .animate-pulse {
    animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1)
      infinite;
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 0.15;
    }
    50% {
      opacity: 0.05;
    }
  }
</style>
